import React, { useEffect, useState } from "react";
import ContestantsComponent from "../../components/contestants/ContestantsComponent";
import Pagination from "react-responsive-pagination";
import "../../../assets/styles/base.css";
import "../../../assets/styles/containers/contestants.css";
import "../../../assets/styles/plugins/pagination.css";
import { useDispatch, useSelector } from "react-redux";
import reduxAction, { fullUrlAction } from "../../../redux/action";
import {
  CONTESTANT_VOTES_PAYMENT,
  FETCH_CONTESTANTS,
  OTP_VERIFICATION,
  VOTE_CONTESTANT,
} from "../../../redux/contestants/types";
import Backdrop from "../../../components/Backdrop";
import Loading from "../../../components/Loading";
import Toast from "../../../components/MessageToast";
import PaymentModal from "../../components/payment/PaymentModal";

export default () => {
  const totalPages = 5;
  const [state, setState] = useState({
    currentPage: 1,
    loading: false,
    voted: {},
    openModel: false,
  });

  const dispatch = useDispatch();
  const allContestants = useSelector((store) => store.contestants);
  const votePayment = useSelector((store) => store.votePayment);

  useEffect(() => {
    if (!allContestants.data.results) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/votings/testingevent1636170314124`,
          actionType: FETCH_CONTESTANTS,
        })
      );
    }
  }, []);

  const onVote = async (candidate) => {
    setState({ ...state, voted: candidate, openModel: true });
  };

  const handleVotesPayment = (personalData) => {
    const { firstName, lastName, email, amount, phone, customAmount } =
      personalData;

    const paymentData = {
      amount: customAmount || amount,
      redirect_url: `${window.location.origin}?candidateId=${state.voted.id}`,
      email,
      phone_number: phone,
      fullname: `${firstName} ${lastName}`,
      currency: "RWF",
    };
    dispatch(
      reduxAction({
        method: "POST",
        path: `/votings/testingevent1636170314124/transactions`,
        actionType: CONTESTANT_VOTES_PAYMENT,
        data: paymentData,
      })
    );
  };

  const handleOTPSubmission = (otp) => {
    if (
      votePayment.payment_data.results &&
      votePayment.payment_data.results.charge.redirect
    ) {
      dispatch(
        fullUrlAction({
          method: "POST",
          path: `${votePayment.payment_data.results.charge.redirect}?solution=${otp}`,
          actionType: OTP_VERIFICATION,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setState({ ...state, openModel: false });
  };

  const contestants = allContestants.data.results
    ? allContestants.data.results.candidates.map((elt) => {
        return {
          id: elt.candidateId,
          name: `${elt.firstName} ${elt.lastName}`,
          imgUrl: elt.photo || "https://source.unsplash.com/random",
          bio:
            elt.bio ||
            `I have ${elt.details.education}, and I can speak ${elt.details.languages}`,
          details: {
            age: elt.details.age || "18<",
            origin: elt.details.origin || "Kigali",
            votes: elt.votes,
          },
        };
      })
    : [];

  return allContestants.loading && !allContestants.data.results ? (
    <Loading />
  ) : (
    <div>
      {allContestants.loading && allContestants.data.results ? (
        <Backdrop />
      ) : (
        <div>
          <PaymentModal
            isOpen={state.openModel}
            handleClose={handleCloseModal}
            content={state.voted}
            onVotePayment={handleVotesPayment}
            votePaymentStore={votePayment}
            onOtpSubmit={handleOTPSubmission}
            voted={state.voted}
          />
          <ContestantsComponent
            contestants={contestants}
            onVote={onVote}
            votedCandidate={state.voted}
            voting={state.loading}
          />
        </div>
      )}
    </div>
  );
};
