import { rest } from "lodash";
import React from "react";
import VerticalImageCard from "../../../components/Cards/verticalImageCard";

export default (props) => {
  const { contestants, ...rest } = props;

  return (
    <div className="contestants-root">
      <p className="title"> All contestants</p>

      <div className="contestants">
        {contestants.map((contestantElt) => (
          <VerticalImageCard
            key={contestantElt.id}
            isListElt
            content={contestantElt}
            {...rest}
          />
        ))}
      </div>
    </div>
  );
};
