import axios from 'axios';
import env from './environment/index';

const axiosInstance = axios.create({
  baseURL: `${env.API_BASE_URL}`,
  headers: {
    Accepted: 'appication/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('au'));
    if (user) {
      config.headers.authorization = user.atkn;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
