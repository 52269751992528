import React from "react";
import { Avatar, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import PulseLoader from "react-spinners/PulseLoader";
import { useWindowDimensions } from "../../../helpers/helperMethods";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  supportedTitle: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #888888",
    lineHeight: "0.1em",
    margin: "10px 0 20px",
  },
  supportedInline: {
    background: "#fff",
    padding: "0 10px",
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default ({ onChoosePaymentMethod }) => {
  const classes = useStyles();
  const handlePaymentMethod = (text) => onChoosePaymentMethod(text);
  const { width } = useWindowDimensions();
  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center">
        Choose Payment Method
      </Typography>
      <div className={classes.inputElt}>
        <Typography variant="body1" className={classes.supportedTitle}>
          <span className={classes.supportedInline}>
            Supported Payment Methods
          </span>
        </Typography>
        <div className={classes.inputElt}>
          <Stack
            direction={width > 600 ? "row" : "column"}
            spacing={5}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Avatar
              alt="Mtn"
              src="https://www.mtn.co.rw/wp-content/uploads/2020/05/mtn-logo.png"
              sx={{ width: 100, height: 100 }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Airtel"
              src="https://airtel.africa/assets/images/airtel-logo/airtel-logo-white-text-vertical.jpg"
              sx={{ width: 100, height: 100 }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Tigo"
              src="https://www.utransto.com/images/mno/tigo_ghana.png"
              sx={{ width: 100, height: 100 }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Visa"
              src="https://s3-symbol-logo.tradingview.com/visa--600.png"
              sx={{ width: 100, height: 100 }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("visa")}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};
