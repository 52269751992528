import React from "react";
import { openLinkInNewTab } from "../../helpers/helperMethods";

export default (props) => {
  const { content } = props;
  return (
    <div className="footerElt">
      <span className="footerSubtitle">{">"}</span>
      <span
        className="footerSubtitle"
        onClick={() => openLinkInNewTab(content.link)}
      >
        {content.label}
      </span>
    </div>
  );
};
