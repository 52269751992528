import React, { useEffect, useState } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/core/ModalUnstyled";
import MobilePaymentForm from "./MobileForm";
import Toast from "../../../components/MessageToast";
import VisaForm from "./VisaForm";
import PaymentMethods from "./PaymentMethods";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import apiService from "../../../services/apiService";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: "50%",
  // height: "70vh",
  height: "fit-content",
  backgroundColor: "#fff",
  p: 2,
  px: 4,
  pb: 3,
  borderRadius: "6px",
  "@media (max-width: 600px)": {
    width: "80%",
    height: "fit-content",
  },
};

export default ({
  isOpen,
  handleClose,
  content,
  onVotePayment,
  formError,
  votePaymentStore,
  onOtpSubmit,
  voted,
}) => {
  const [state, setState] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    amount: 0,
    formError: null,
    customAmount: null,
    isCardForm: false,
    choosePaymentMethod: true,
    paymentMethod: null,
    currency: "RWF",
  });

  useEffect(() => {
    setState({ ...state, formError });
  }, [formError]);

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;

    if (name) {
      return setState({ ...state, [name]: value, formError: "" });
    }

    setState({ ...state, [id]: value, formError: "" });
  };

  const handlePhoneChange = (phone) => setState({ ...state, phone });

  const config = {
    public_key: "FLWPUBK-baf926e6ddfa3283a6d724a10598627b-X",
    tx_ref: Date.now(),
    amount: state.amount,
    currency: state.currency,
    payment_options: "card,mobilemoney",
    customer: {
      email: state.email,
      phonenumber: state.phone && state.phone.slice(2),
      name: `${state.firstName} ${state.lastName}`,
    },
    customizations: {
      title: "Noneho.com | Payment For Vote",
      description: "Choose the payment method",
      logo: "https://demand.noneho.com/assets/img/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const onFormSubmit = () => {
    const { firstName, lastName, email, amount, phone } = state;
    if (!firstName || !lastName || !email) {
      return setState({
        ...state,
        formError: `Please Make Sure all fields are field, Missing ${
          !firstName ? "First Name" : !lastName ? "Last Name" : "Email"
        }`,
      });
    }
    handleFlutterPayment({
      callback: async (response) => {
        setState({ ...state, loading: true });
        const votingObj = {
          status: response.status,
          transId: response.transaction_id,
          tx_ref: response.tx_ref,
          amount: response.amount,
          currency: response.currency,
          customer: response.customer,
        };

        const voteResponse = await apiService({
          method: "PATCH",
          path: `/votings/testingevent1636170314124/candidates/${voted.id}`,
          data: votingObj,
        });

        Toast({
          message: voteResponse.message,
          type: voteResponse.error ? "error" : "success",
          onClose: () => {
            if (!voteResponse.error) {
              handleClose();
            }
          },
        });

        setState({
          ...state,
          loading: voteResponse && false,
          paymentMethod: null,
        });
        closePaymentModal();
      },
      onClose: () => {},
    });
  };

  const onChoosePaymentMethod = (method) => {
    setState({ ...state, paymentMethod: method });
  };

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={isOpen}
        onClose={() => {
          setState({
            ...state,
            paymentMethod: null,
          });
          handleClose();
        }}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          {!state.paymentMethod ? (
            <PaymentMethods onChoosePaymentMethod={onChoosePaymentMethod} />
          ) : (
            <div>
              {state.paymentMethod === "mobile" ? (
                <MobilePaymentForm
                  onInputChange={handleInputChange}
                  onPhoneChange={handlePhoneChange}
                  title={content.name}
                  content={state}
                  onFormSubmit={onFormSubmit}
                  formError={state.formError}
                  loading={votePaymentStore.loading}
                />
              ) : (
                <VisaForm
                  onInputChange={handleInputChange}
                  onPhoneChange={handlePhoneChange}
                  title={content.name}
                  content={state}
                  onFormSubmit={onFormSubmit}
                  formError={state.formError}
                  loading={votePaymentStore.loading}
                />
              )}
            </div>
          )}
        </Box>
      </StyledModal>
    </div>
  );
};
