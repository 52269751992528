import React from "react";
import FooterElement from "../../components/Footer/footerElement";
import "../../assets/styles/base.css";
import "../../assets/styles/components/footer.css";

export default () => {
  const footerContent = {
    goals: [
      "Monetize Your Creative Work with Us",
      "Understand Your Media Performance",
      "Advertise Through Us",
    ],
    platforms: [
      { label: "INYARWANDA News", link: "https://inyarwanda.com/" },
      { label: "INYARWANDA Music", link: "https://music.inyarwanda.com/" },
      { label: "INYARWANDA Tv", link: "https://tv.inyarwanda.com/" },
    ],
  };
  return (
    <div
      style={{
        backgroundColor: window.location.pathname.includes("publishers")
          ? "#613A01"
          : "#092f53",
      }}
      id="footerRoot"
    >
      <div className="row-space-between">
        <div className="platforms">
          <p className="not-centered-subtitle">Platforms</p>
          <div>
            {footerContent.platforms.map((platform) => (
              <FooterElement key={platform.label} content={platform} />
            ))}
          </div>
        </div>
      </div>

      <p className="footerText">
        Made with ❤️ from INYARWANDA Dev Team, Copyright Inyarwanda Ltd 2021.
      </p>
    </div>
  );
};
