import React, { Component } from "react";
import { Markup } from "interweave";
import ImageTextCard from "../../../components/Cards/ImageTextCard";
import { rest } from "lodash";

export default (props) => {
  const { content, ...rest } = props;

  return (
    <div className="contestant">
      <ImageTextCard content={content} {...rest} />
      <div className="main-content">
        <Markup content={content.text} />
      </div>
    </div>
  );
};
