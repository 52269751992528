import reducers from "./reducers";

const contestantsReducers = {
  contestant: reducers.getContestantReducer,
  contestants: reducers.getContestantsReducer,
  voteContestant: reducers.voteContestantReducer,
  votePayment: reducers.votePaymentReducer,
};

export { contestantsReducers };
