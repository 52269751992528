import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import ContestantsContainer from "../containers/contestants/ContestantsContainer";
import ContestantContainer from "../containers/contestants/ContestantContainer";

export class Contestant extends Component {
  render() {
    const contestantId = this.props.match.params.contestantId;

    return contestantId ? (
      <AppLayout>
        <ContestantContainer contestantId={contestantId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <ContestantsContainer />
      </AppLayout>
    );
  }
}

export default Contestant;
