import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  navigateToPage,
  useWindowDimensions,
} from "../../helpers/helperMethods";
import { useHistory } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "97%",
  },
  cardImg: { height: "40vh" },
  cartContent: {
    backgroundColor: "#f5f5f5",
  },
  cardActions: {
    padding: "10px 5%",
  },
  smallDetails: { marginRight: "5px" },
}));

export default (props) => {
  const { content, onVote, votedCandidate, voting } = props;
  const { width } = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();

  const viewContestant = () => navigateToPage(`/${content.id}`, history);
  return (
    <Card
      className={classes.root}
      style={{
        height: width > 600 ? "70vh" : "82vh",
      }}
    >
      <CardActionArea onClick={viewContestant}>
        <div
          style={{
            backgroundImage: `url("${content.imgUrl}")`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "48vh",
          }}
        ></div>
        <CardContent
          className={classes.cartContent}
          style={{
            height: width > 600 ? "10vh" : "18vh",
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {content.name}
          </Typography>

          <Typography variant="subtitle2">
            Votes: {content.details.votes}
          </Typography>
          <br />
          <Typography variant="body2" color="text.secondary">
            <span className={classes.smallDetails}>
              Age:{content.details.age}
            </span>
            <span className={classes.smallDetails}>
              From: {content.details.origin}
            </span>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button
          onClick={() => onVote(content)}
          className={classes.cardVoteBtn}
          style={{
            display: "inline",
            padding: "10px",
            width: "50%",
            backgroundColor: "#888888",
            color: "#fff",
            marginLeft: "25%",
          }}
        >
          {voting && votedCandidate.id === content.id ? (
            <PulseLoader size={7} margin={3} color={"#fff"} loading={voting} />
          ) : (
            "Vote"
          )}
        </Button>
      </CardActions>
    </Card>
  );
};
