import React, { useEffect, useState } from 'react';
import ContestantComponent from '../../components/contestants/ContestantComponent';
import Toast from '../../../components/MessageToast';
import '../../../assets/styles/base.css';
import '../../../assets/styles/containers/contestants.css';
import {
  FETCH_CONTESTANT,
  VOTE_CONTESTANT,
} from '../../../redux/contestants/types';
import { useDispatch, useSelector } from 'react-redux';
import reduxAction from '../../../redux/action';
import Loading from '../../../components/Loading';
import apiService from '../../../services/apiService';

export default ({ contestantId }) => {
  const [state, setState] = useState({
    openModal: false,
    loading: false,
    votedCandidate: '',
  });

  const dispatch = useDispatch();

  const singleContestant = useSelector((store) => store.contestant);
  // const voteContestant = useSelector((store) => store.voteContestant);

  useEffect(() => {
    dispatch(
      reduxAction({
        method: 'GET',
        path: `/votings/testingevent1636170314124/candidates/${contestantId}`,
        actionType: FETCH_CONTESTANT,
      })
    );
  }, []);

  const onVote = async (candidate) => {
    setState({ ...state, votedCandidate: candidate, openModel: true });
  };

  const contestant = singleContestant.data.results
    ? {
        id: contestantId,
        name: `${singleContestant.data.results.firstName} ${singleContestant.data.results.lastName}`,
        imgUrl:
          singleContestant.data.results.photo ||
          'https://pbs.twimg.com/profile_images/1120876686848008193/zcMv6ghc.jpg',
        details: {
          age: singleContestant.data.results.details.age,
          origin: singleContestant.data.results.details.origin,
          votes: singleContestant.data.results.votes || 0,
        },
        bio:
          singleContestant.data.results.bio ||
          `I have ${singleContestant.data.results.details.education}, and I can speak ${singleContestant.data.results.details.languages}`,
        text: singleContestant.data.results.text || '',
      }
    : {};

  return (singleContestant.loading && !singleContestant.data.results) ||
    (singleContestant.loading &&
      singleContestant.data.results &&
      singleContestant.data.results.candidateId !== contestantId) ? (
    <Loading />
  ) : (
    <div>
      <ContestantComponent
        content={contestant}
        onVote={onVote}
        voting={state.loading}
      />
    </div>
  );
};
