import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Blogs from "../views/pages/HomePage";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <Route exact path="/" component={Blogs} />
          <Route exact path="/:contestantId" component={Blogs} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default AppRoute;
