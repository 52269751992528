import React from "react";
import { Avatar, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import PulseLoader from "react-spinners/PulseLoader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useWindowDimensions } from "../../../helpers/helperMethods";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  submitBtn: {
    padding: "15px 0",
    width: "50%",
    backgroundColor: "green",
    textAlign: "center",

    "&:hover": {
      background: "green",
      cursor: "pointer",
    },
    "@media (max-width: 600px)": {
      padding: "10px 0",
      width: "100%",
    },
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  onInputChange,
  onPhoneChange,
  content,
  title,
  onFormSubmit,
  formError,
  loading,
}) => {
  const classes = useStyles();
  const onSubmit = () => onFormSubmit();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Typography variant="h4">Voting: {title || " ⚠️ Unset"}</Typography>
      <Typography variant="subtitle1">Personal Information</Typography>
      {width > 600 && <br />}
      <div className={classes.groupInputElt}>
        <TextField
          required
          id="firstName"
          label="First Name"
          placeholder="Enter your first name"
          onChange={onInputChange}
          value={content.firstName}
        />

        <TextField
          required
          id="lastName"
          label="Last Name"
          placeholder="Enter your last name"
          onChange={onInputChange}
          value={content.lastName}
        />
      </div>
      <div className={classes.inputElt}>
        <TextField
          required
          id="email"
          label="Email"
          placeholder="Enter your email"
          onChange={onInputChange}
          value={content.email}
        />
      </div>
      <PhoneInput
        country={"rw"}
        className={classes.inputElt}
        value={content.phone}
        onChange={onPhoneChange}
        inputStyle={{ width: "100%" }}
      />

      <div className={classes.groupInputElt}>
        <FormControl fullWidth>
          <InputLabel id="amount-label">Amount</InputLabel>
          <Select
            labelId="amount-label"
            id="amount"
            value={content.amount}
            label="Amount"
            name="amount"
            onChange={onInputChange}
            placeholder="Enter Amount"
            disabled={content.customAmount}
          >
            <MenuItem value={0}>
              <span className={classes.selectTitle}>Choose Amount</span>
            </MenuItem>
            <MenuItem value={100}>100 Rwf: 1 Vote </MenuItem>
            <MenuItem value={1000}>1,000 Rwf: 10 Votes</MenuItem>
            <MenuItem value={10000}>10,000 Rwf:100 Votes</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="customAmount"
          label="Custom Amount"
          placeholder="Make Sure it's a Multiple of 100"
          onChange={onInputChange}
          value={content.customAmount}
          disabled={content.amount > 0}
        />
      </div>
      <Typography className={classes.errorMsg} variant="subtitle1">
        {formError}
      </Typography>
      <br />
      <div className={classes.inputElt}>
        <Button
          variant="contained"
          className={classes.submitBtn}
          onClick={onSubmit}
        >
          {loading ? (
            <PulseLoader size={7} margin={3} color={"#fff"} loading={loading} />
          ) : (
            "Proceed To Payment"
          )}
        </Button>
      </div>
    </div>
  );
};
